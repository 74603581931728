import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import newsStyles from "../styles/pages/news.module.css"
import { FaChevronCircleRight } from 'react-icons/fa';

import babaeAtBaril from "../images/news/article-1/babae-at-baril-scene.jpg"
import KitchenQueen from "C:/Users/Arthouse 2/Desktop/edimcf-websites/creative-caboose/src/images/news/article-2/img1.jpeg"
// Add more images by just copy pasting one of the lines above and just changing the imgVariable and the imgPath:
// import imgVariable from "imgPath"
// Also don't forget to add your images to images/news/article-X (replace the X with the no. of the article) folder

const NewsPage = () => {
    return (
      <Layout location="news">
        <SEO title="News" />
        <div className={ newsStyles.withPosts }>
          <div className={ newsStyles.container }>
            <h1 className={ newsStyles.title }>News</h1>
            <div className={ newsStyles.content }>
              <h4>2 Posts</h4>
              {/* Copy-paste the section below everytime you add a new article */}
              {/* <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={ imgVariable } alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>INSERT TITLE HERE</h3>
                  <h5>INSERT POST DATE HERE</h5>
                  <p>INSERT A PARAGRAPH HERE</p>
                  <p>INSERT NEXT PARAGRAPH OR DELETE</p> 
                  <Link to="/news/article-X/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div> */}


               <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={ KitchenQueen } alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>The return of the ‘Kitchen Queen’</h3>
                  <h5>January 6, 2020</h5>
                  <p>Cignal TV, the country's #1 Pay TV provider with over 3 million subscribers welcomes back the Queen of the Kitchen, seasoned actress Helen Gamboa Sotto, with the Season 3 launch of “From Helen's Kitchen” on Colours and TV5</p>
                  <Link to="/news/article-2/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>


              <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={ babaeAtBaril } alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>Congratulations "Babae at Baril"!</h3>
                  <h5>November 26, 2020</h5>
                  <p>Congratulations Babae at Baril for winning the Best Picture, Best Director, Best Cinematography, Best Actress and Best Production Design in Gawad Urian awards!</p>
                  <Link to="/news/article-1"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );

    // For when there are no articles, replace the code above with this (uncommented, of course):
    // return (
    // <Layout location="news">
    //     <SEO title="News" />
    //     <div className={ newsStyles.noNews }>
    //         <div className={ newsStyles.message }>
    //             <div className={ newsStyles.messageWrapper }>
    //                 <h1>No news articles yet.</h1>
    //                 <h3>Stay tuned for future updates on projects we take on.</h3>
    //                 <button onClick={() => {
    //                     window.history.back()
    //                 }}>Go Back</button>
    //             </div>
    //         </div>
    //     </div>
    // </Layout>
    // );
  }

export default NewsPage